import React from 'react';

function About() {
  return (
    <div>
      <h2>&#3894;&bull;&#9480;&#9480;&#2920;&#9825;&#2919;&#9480;&#9480;&bull;&#3894;</h2>
      <h2>my interests</h2>
      <p>modern family enjoyer, dutch bros connoisseur, kpop enthusiast & taylor swift  <span class="lover">lover</span></p>
      <h2>my favorite games</h2>
      <p>dead by daylight, overwatch 2, dress to impress & genshin impact!</p>
      <h2>my favorite people</h2>
      <p>my boyfriend, sia, girlcode staff & members</p>
    </div>
  );
}

export default About;
