import React from 'react';

function Header() {
  return (
    <header>
      <h1>margo &#666;&#9825;&#606; <br/> <a href="https://discord.gg/girlcode" className="discord-link">discord.gg/girlcode</a></h1>
      <p>dm <span id="discord-username">patapatapeppy</span> on discord for custom banners &#9679; she/her</p>
    </header>
  );
}

export default Header;
