import React from 'react';

function Interests() {
  return (
    <div>
      <h2>&#3894;&bull;&#9480;&#9480;&#2920;&#9825;&#2919;&#9480;&#9480;&bull;&#3894;</h2>
      <h2>my services</h2>
      <p>3d logo animations</p>
      <p>editing youtube videos, tiktoks</p>
      <p>animated discord profile & discord server banners</p>
      <p>if you need any work done dm me on discord at</p>
      <p>patapatapeppy!</p>
    </div>
  );
}

export default Interests;
