import React from 'react';

function Home() {
  return (
    <div>
      <h2>&#3894;&bull;&#9480;&#9480;&#2920;&#9825;&#2919;&#9480;&#9480;&bull;&#3894;</h2>
      <h2>hello ~ welcome to my page!</h2>
      <p>my name is margo</p>
      <p>i am the co owner of girl code &#8339;&#8338;</p>
      <p># i work w AE, blender, etc! if u need</p>
      <p>any work done dm me on discord at</p>
      <p>patapatapeppy!</p>
    </div>
  );
}

export default Home;
