import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes, Route,
  Link
} from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import Home from './components/Home';
import Interests from './components/Interests';

function App() {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <Router>
      <div>
        <Header />
        <nav>
          <ul className="nav-buttons">
            <li>
              <Link to="/" className={`heart-button ${active ? 'active' : ''}`} onClick={handleClick}>
                <ion-icon name="heart"></ion-icon>
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/about" className={`heart-button ${active ? 'active' : ''}`} onClick={handleClick}>
                <ion-icon name="heart"></ion-icon>
                <span>About</span>
              </Link>
            </li>
            <li>
              <Link to="/artwork" className={`heart-button ${active ? 'active' : ''}`} onClick={handleClick}>
                <ion-icon name="heart"></ion-icon>
                <span>Art</span>
              </Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/artwork" element={<Interests />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
